import React from "react";
import { Route } from "react-router-dom";
// import { useAuth } from "../auth-context/auth.context";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const ProtectedRoute = ({ ...rest }) => {
  const history = useHistory();
  const { route } = useAuthenticator((context) => [context.route]);
  console.log("route", route);
  // let { user } = useAuth();
  if (route !== "authenticated" && route !== "setup") {
    return (
      <SweetAlert
        title="You must be signed in!"
        onCancel={() => history.push("/auth/sign-in")}
        onConfirm={() => history.push("/auth/sign-in")}
        confirmBtnCssClass={"px-5"}
        react-bootstrap-sweetalert
      />
    );
  }

  return <Route {...rest} />;
};
