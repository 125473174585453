export const washCalendar = [
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "FLAT",
    accessor: "flat",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
];
