/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Avatar, Box, Flex, FormLabel, Icon, Select, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect, useState } from "react";
import { MdAddTask, MdAttachMoney, MdBarChart, MdFileCopy } from "react-icons/md";
import { Apartment } from "components/icons/Icons";
import ComplexTable from "views/admin/dashboard/components/ComplexTable";
import Tasks from "views/admin/default/components/Tasks";
import { washCalendar } from "views/admin/dashboard/variables/columnsData";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { columnsDataCheck, columnsDataComplex } from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import { getDateFrontendFormat } from "helpers/formHelpers";

import { API } from "aws-amplify";

const flatDate = [
  { date: "2023-12-01", flat: "2.5 li 2OG" },
  { date: "2023-12-02", flat: "3.5 mi 2OG" },
  { date: "2023-12-04", flat: "3.5 re 2OG" },
  { date: "2023-12-05", flat: "2.5 re 3OG" },
  { date: "2023-12-06", flat: "2.5 li EG" },
  { date: "2023-12-07", flat: "3.5 mi EG" },
  { date: "2023-12-08", flat: "3.5 re EG" },
  { date: "2023-12-09", flat: "2.5 li 1OG" },
  { date: "2023-12-11", flat: "3.5 mi 1OG" },
  { date: "2023-12-12", flat: "3.5 re 1OG" },
  { date: "2023-12-13", flat: "2.5 li 2OG" },
  { date: "2023-12-14", flat: "3.5 mi 2OG" },
  { date: "2023-12-15", flat: "3.5 re 2OG" },
  { date: "2023-12-16", flat: "2.5 re 3OG" },
  { date: "2023-12-18", flat: "2.5 li EG" },
  { date: "2023-12-19", flat: "3.5 mi EG" },
  { date: "2023-12-20", flat: "3.5 re EG" },
  { date: "2023-12-21", flat: "2.5 li 1OG" },
  { date: "2023-12-22", flat: "3.5 mi 1OG" },
  { date: "2023-12-23", flat: "3.5 re 1OG" },
  { date: "2023-12-27", flat: "2.5 li 2OG" },
  { date: "2023-12-28", flat: "3.5 mi 2OG" },
  { date: "2023-12-29", flat: "3.5 re 2OG" },
  { date: "2023-12-30", flat: "2.5 re 3OG" },
];

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const [washData, setWashData] = useState([]);

  const addData = async () => {
    const apiName = "api281f7400";
    const path = "/calendar";

    const promises = flatDate.map(async (item) => {
      const data = {
        body: item,
      };
      try {
        return await API.post(apiName, path, data);
      } catch (err) {
        console.error("Error adding item:", item, err);
        throw err; // re-throwing the error to be caught outside
      }
    });

    try {
      await Promise.all(promises);
      console.log("All items added successfully");
    } catch (error) {
      console.error("Error occurred while adding some items:", error);
    }
  };

  const fetchData = async () => {
    try {
      const data = await API.get("api281f7400", "/calendar/", {});
      // sort
      data.sort(function (a, b) {
        return a.date > b.date ? 1 : b.date > a.date ? -1 : 0;
      });

      const extData = data.map((item) => {
        return {
          ...item,
          date: getDateFrontendFormat(item.date),
          status: item.flat === "2.5 re 3OG" || item.flat === "2.5 li 1OG" ? "Approved" : "Disable",
        };
      });

      setWashData(extData);
    } catch (error) {
      console.log("error fetching data", error);
      setWashData([]);
    }
  };

  // addData();

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }} gap="20px" mb="20px">
        <MiniStatistics
          startContent={<IconBox w="56px" h="56px" bg={boxBg} icon={<Icon w="32px" h="32px" as={Apartment} color={brandColor} />} />}
          name="Flats"
          value="10"
        />
        <MiniStatistics growth="-23%" name="Your Room" value="2.5 re 3OG" />
        <MiniStatistics growth="+23%" name="Next Washing" value="Tomorrow" />
      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ComplexTable title="Wash September" columnsData={washCalendar} tableData={washData.filter((item) => /09.2023$/.test(item?.date))} />
        <ComplexTable title="Wash October" columnsData={washCalendar} tableData={washData.filter((item) => /10.2023$/.test(item?.date))} />
      </SimpleGrid>
    </Box>
  );
}
