/* eslint-disable */
/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";

import { useHistory } from "react-router-dom";
import { NavLink } from "react-router-dom";

// Chakra imports
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
// Custom components
import { HSeparator } from "components/separator/Separator";
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import { FcGoogle } from "react-icons/fc";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";

import * as yup from "yup";
import { useErrorMessageFactory, useYupValidationResolver, useToggleState } from "hooks";
// import { signIn, signOut } from "helpers/auth";

// import { useAuth } from "../../../auth-context/auth.context";
// import AuthApi from "../../../api/auth";
import { Auth } from "aws-amplify";

const schema = yup
  .object({
    code: yup.string().intlRequired(),
    email: yup.string().validateEmail().intlRequired(),
    password: yup.string().intlRequired(),
  })
  .required();

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const [showAlert, setShowAlert] = React.useState(false);

  const handleClick = () => setShow(!show);

  const history = useHistory();
  // const { setUser } = useAuth();
  // const { user } = useAuth();

  const {
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
    shouldFocusError: true,
    delayError: undefined,
    defaultValues: {
      code: "",
      email: "",
      password: "",
    },
    resolver: useYupValidationResolver(schema),
  });

  const handleResetPassword = async () => {
    try {
      const code = getValues("code");
      const username = getValues("email");
      const newPassword = getValues("password");
      const userAuthData = await Auth.forgotPasswordSubmit(username, code, newPassword);
      setShowAlert(true);
    } catch (err) {
      console.log(err);
      // setButtonText("Sign in");
      if (err.message) {
        // return setError(err.message);
      }
      // return setError("There has been an error.");
    }
  };

  const handleCloseButton = () => {
    setShowAlert(false);
    history.push("/sign-in");
  };

  return (
    <DefaultAuth>
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
        alignSelf="center"
      >
        {showAlert && (
          <Alert status="success">
            <AlertIcon />
            <Box>
              <AlertTitle>Success!</AlertTitle>
              <AlertDescription>Your password has been changed.</AlertDescription>
            </Box>
            <CloseButton alignSelf="flex-start" position="relative" right={-1} top={-1} onClick={handleCloseButton} />
          </Alert>
        )}

        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Reset Password
          </Heading>
          <Text mb="36px" ms="4px" color={textColorSecondary} fontWeight="400" fontSize="md">
            Enter your code!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >
          <form onSubmit={handleSubmit(handleResetPassword)}>
            <FormControl isInvalid={errors.code}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Code<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                {...register("code")}
                id="code"
                placeholder="code"
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="text"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <FormErrorMessage mt={-3} mb={5}>
                {errors.code && errors.code.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.email}>
              <FormLabel display="flex" ms="4px" fontSize="sm" fontWeight="500" color={textColor} mb="8px">
                Email<Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                {...register("email")}
                id="email"
                placeholder="email"
                variant="auth"
                fontSize="sm"
                ms={{ base: "0px", md: "0px" }}
                type="email"
                mb="24px"
                fontWeight="500"
                size="lg"
              />
              <FormErrorMessage mt={-3} mb={5}>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.password}>
              <FormLabel ms="4px" fontSize="sm" fontWeight="500" color={textColor} display="flex">
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  {...register("password")}
                  fontSize="sm"
                  placeholder="Min. 8 characters"
                  mb="24px"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>
              <FormErrorMessage mt={-3} mb={5}>
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl>
              <Button fontSize="sm" variant="brand" fontWeight="500" w="100%" h="50" mb="24px" isLoading={isSubmitting} type="submit">
                Change Password
              </Button>
            </FormControl>
          </form>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
