/* eslint-disable no-template-curly-in-string */
import { addMethod, string, number, object, mixed, array } from "yup";

function minValue(min, config = {}) {
  return this.test({
    name: "value_too_small",
    message: "validation.messages.value_too_small--${min}",
    test: (value) => +value >= min,
    params: {
      min,
    },
    ...config,
  });
}

function biggerThanValueOf(min, refName, config = {}) {
  return this.test({
    name: "value_bigger_than_value_of",
    message: "validation.messages.bigger_than_value_of--${min}",
    test: (value, ctx) => +value >= +ctx.parent[refName],
    params: {
      min,
    },
    ...config,
  });
}

function smallerThanValueOf(max, refName, config = {}) {
  return this.test({
    name: "value_smaller_than_value_of",
    message: "validation.messages.smaller_than_value_of--${max}",
    test: (value, ctx) => +value <= +ctx.parent[refName],
    params: {
      max,
    },
    ...config,
  });
}

function maxValue(max, config = {}) {
  return this.test({
    name: "value_too_big",
    message: "validation.messages.value_too_big--${max}",
    test: (value) => +value <= max,
    params: {
      max,
    },
    ...config,
  });
}

function minTextLength(min, config = {}) {
  return this.test({
    name: "text_too_short",
    message: "validation.messages.text_too_short--${min}",
    test: (value) => String(value).length < min,
    params: {
      min,
    },
    ...config,
  });
}

function maxTextLength(max, config = {}) {
  return this.test({
    name: "text_too_long",
    message: "validation.messages.text_too_long--${max}",
    test: (value) => String(value).length <= max,
    params: {
      max,
    },
    ...config,
  });
}

function intlMatch(regexp, format) {
  return this.test({
    name: "format",
    message: "validation.messages.format--${format}",
    test: (value) => {
      const r = new RegExp(regexp);
      return r.test(value);
    },
    params: {
      format,
    },
  });
}

function validateEmail() {
  return this.test({
    name: "email",
    message: "errors.validation.email.is_invalid",
    test: (value) => {
      // const r = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const r = /^(?!.*\.\.)(^[^.][A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$)/i;

      return value ? r.test(value) : true;
    },
  });
}

function intlRequired(msg = "validation.messages.required") {
  return this.required(msg);
}

function maxItems(max, config = {}) {
  return this.test({
    name: "max_items",
    message: "validation.messages.value_too_big--${max}",
    test: (value) => {
      return value?.length <= max;
    },
    params: {
      max,
    },
    ...config,
  });
}

function mod97(string) {
  let checksum = string.slice(0, 2);
  let fragment;
  for (let offset = 2; offset < string.length; offset += 7) {
    fragment = String(checksum) + string.substring(offset, offset + 7);
    checksum = parseInt(fragment, 10) % 97;
  }
  return checksum;
}

function isValidIBANNumber(input) {
  const CODE_LENGTHS = {
    AD: 24,
    AE: 23,
    AT: 20,
    AZ: 28,
    BA: 20,
    BE: 16,
    BG: 22,
    BH: 22,
    BR: 29,
    CH: 21,
    CR: 21,
    CY: 28,
    CZ: 24,
    DE: 22,
    DK: 18,
    DO: 28,
    EE: 20,
    ES: 24,
    FI: 18,
    FO: 18,
    FR: 27,
    GB: 22,
    GI: 23,
    GL: 18,
    GR: 27,
    GT: 28,
    HR: 21,
    HU: 28,
    IE: 22,
    IL: 23,
    IS: 26,
    IT: 27,
    JO: 30,
    KW: 30,
    KZ: 20,
    LB: 28,
    LI: 21,
    LT: 20,
    LU: 20,
    LV: 21,
    MC: 27,
    MD: 24,
    ME: 22,
    MK: 19,
    MR: 27,
    MT: 31,
    MU: 30,
    NL: 18,
    NO: 15,
    PK: 24,
    PL: 28,
    PS: 29,
    PT: 25,
    QA: 29,
    RO: 24,
    RS: 22,
    SA: 24,
    SE: 24,
    SI: 19,
    SK: 24,
    SM: 27,
    TN: 24,
    TR: 26,
    AL: 28,
    BY: 28,
    EG: 29,
    GE: 22,
    IQ: 23,
    LC: 32,
    SC: 31,
    ST: 25,
    SV: 28,
    TL: 23,
    UA: 29,
    VA: 22,
    VG: 24,
    XK: 20,
  };
  const iban = String(input)
    .toUpperCase()
    .replace(/[^A-Z0-9]/g, ""); // keep only alphanumeric characters
  const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

  // check syntax and length
  if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
    return false;
  }

  let digits;
  // rearrange country code and check digits, and convert chars to ints
  // eslint-disable-next-line prefer-const
  digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
    return letter.charCodeAt(0) - 55;
  });
  // final check
  return mod97(digits) === 1;
}

function validateIban() {
  return this.test({
    name: "email",
    message: "errors.validation.iban.isInvalid",
    test: (value) => {
      return value ? isValidIBANNumber(value) : true;
    },
  });
}

addMethod(number, "minValue", minValue);
addMethod(number, "maxValue", maxValue);
addMethod(string, "minValue", minValue);
addMethod(string, "maxValue", maxValue);
addMethod(string, "biggerThanValueOf", biggerThanValueOf);
addMethod(string, "smallerThanValueOf", smallerThanValueOf);
addMethod(string, "minTextLength", minTextLength);
addMethod(string, "maxTextLength", maxTextLength);
addMethod(mixed, "intlRequired", intlRequired);
addMethod(string, "intlRequired", intlRequired);
addMethod(number, "intlRequired", intlRequired);
addMethod(object, "intlRequired", intlRequired);
addMethod(array, "maxItems", maxItems);
addMethod(string, "intlMatch", intlMatch);
addMethod(string, "validateEmail", validateEmail);
addMethod(string, "validateIban", validateIban);
