import dayjs from 'dayjs';

export const stringToBoolFormatter = (data) => {
  const formattedData = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(data).map((key) => {
    if (data[key] === 'true') {
      formattedData[key] = true;
    } else if (data[key] === 'false') {
      formattedData[key] = false;
    } else {
      formattedData[key] = data[key];
    }
  });

  return formattedData;
};

export const boolToStringFormatter = (data) => {
  const formattedData = {};
  // eslint-disable-next-line array-callback-return
  Object.keys(data || {}).map((key) => {
    if (data[key] === true) {
      formattedData[key] = 'true';
    } else if (data[key] === false) {
      formattedData[key] = 'false';
    } else {
      formattedData[key] = data[key];
    }
  });

  return formattedData;
};

export const getDate = (date) => {
  const convertedDate = date ? dayjs(date, 'YYYY-MM-DD').toDate() : null;

  if (convertedDate && convertedDate.toString() === 'Invalid Date') {
    return undefined;
  }
  return convertedDate;
};

export const getDateFrontendFormat = (date) => {
  const convertedDate = date
    ? dayjs(date, 'YYYY-MM-DD').format('DD.MM.YYYY')
    : '';

  if (convertedDate.toString() === 'Invalid Date') {
    return undefined;
  }

  return convertedDate;
};

export const getDateTimeFrontendFormat = (date) => {
  const convertedDate = date
    ? dayjs(date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm:ss')
    : '';

  if (convertedDate.toString() === 'Invalid Date') {
    return undefined;
  }

  return convertedDate;
};

export const dateToApiFormat = (date) => {
  const convertedDate = dayjs(date).format('YYYY-MM-DD');

  if (convertedDate.toString() === 'Invalid Date') {
    return '';
  }

  return convertedDate;
};

export const longIntegerFormat = (value) => {
  return value === null ? '-' : new Intl.NumberFormat('de-CH').format(value);
};

export const removeSecondLineFromArticle = (string) => {
  if (string.indexOf('\n')) {
    return string
      .split('\n')
      .splice(0, 1)[0]
      .replace(/(\r\n|\n|\r)/gm, '');
  }
  if (string.indexOf('\r')) {
    return string
      .split('\r')
      .splice(0, 1)[0]
      .replace(/(\r\n|\n|\r)/gm, '');
  }
  return string;
};
