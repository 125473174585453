import React from "react";
import ReactDOM from "react-dom";
import "./services/yupCustomMethods";

import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";

// import { AuthProvider } from "./auth-context/auth.context";
import { ProtectedRoute } from "./layouts/protected.route.js";

import { Authenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

if ("serviceWorker" in navigator && "PushManager" in window) {
  navigator.serviceWorker
    .register("/sw.js")
    .then(function (registration) {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch(function (error) {
      console.error("Service Worker registration failed:", error);
    });
}

function subscribeUser() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(function (registration) {
      if (!registration.pushManager) {
        console.log("Push manager unavailable.");
        return;
      }

      registration.pushManager
        .subscribe({
          userVisibleOnly: true, // Always show notification when received
          applicationServerKey: urlBase64ToUint8Array("YOUR_PUBLIC_VAPID_KEY_HERE"),
        })
        .then(function (subscription) {
          console.log("Subscribed:", subscription);
          // Here, you would send the subscription to your backend for storage
        })
        .catch(function (error) {
          console.error("Failed to subscribe the user: ", error);
        });
    });
  }
}

function urlBase64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// Request permission and get the subscription:
navigator.serviceWorker.ready.then(function (registration) {
  registration.pushManager.getSubscription().then(function (subscription) {
    if (subscription) {
      console.log("Already subscribed:", subscription);
      // Optionally update the backend if any details of the subscription change
    } else {
      // Ask the user for permission to send them push notifications
      Notification.requestPermission().then(function (permission) {
        if (permission === "granted") {
          subscribeUser();
        } else {
          console.error("Permission not granted for Notification");
        }
      });
    }
  });
});

ReactDOM.render(
  <ChakraProvider theme={theme}>
    {/* <AuthProvider userData={user}> */}
    <Authenticator.Provider>
      <React.StrictMode>
        <ThemeEditorProvider>
          <HashRouter>
            <Switch>
              <Route path={`/auth`} component={AuthLayout} />
              <ProtectedRoute path={`/admin`} component={AdminLayout} />
              <ProtectedRoute path={`/rtl`} component={RtlLayout} />
              <Redirect from="/" to="/admin" />
            </Switch>
          </HashRouter>
        </ThemeEditorProvider>
      </React.StrictMode>
      {/* </AuthProvider> */}
    </Authenticator.Provider>
  </ChakraProvider>,
  document.getElementById("root")
);
